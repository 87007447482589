<template>
  <main 
    v-if="$parent.isAuth"
    class="main profile-page page-inside" 
  >
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="page-inside-top">
          <div class="category-list">
            <Categories @setProductCategory="setProductCategory" />
          </div>
        </div>
        <div class="page-inside-wrapper">
          <div class="wrapper">
            <div class="nav-container">
              <div class="title small">
                {{ $t('Profile') }}
              </div>
              <div class="nav">
                <router-link 
                  to="/profile/settings" 
                  class="item"
                >
                  <svg 
                    width="26" 
                    height="26" 
                    viewBox="0 0 26 26" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      d="M25.221 15.8492L22.8312 13.9804C22.8615 13.6923 22.8875 13.3554 22.8875 12.9989C22.8875 12.6425 22.8626 12.3056 22.8312 12.0174L25.2232 10.1476C25.6674 9.79662 25.7887 9.17368 25.506 8.6548L23.0208 4.355C22.7553 3.86968 22.1736 3.6205 21.5832 3.83825L18.7633 4.97032C18.2227 4.58032 17.6561 4.251 17.07 3.98775L16.641 0.996684C16.5706 0.429 16.0766 0 15.4927 0H10.5094C9.92546 0 9.43258 0.429 9.36321 0.988L8.93315 3.98993C8.36546 4.24562 7.80758 4.57062 7.24315 4.9725L4.41565 3.83718C3.88585 3.63243 3.2521 3.86207 2.98885 4.34525L0.500463 8.65043C0.206897 9.14662 0.328213 9.79118 0.782147 10.1509L3.17196 12.0196C3.13403 12.3847 3.11565 12.7021 3.11565 13.0001C3.11565 13.298 3.13408 13.6154 3.17196 13.9816L0.779963 15.8514C0.335779 16.2034 0.215529 16.8264 0.498279 17.3442L2.98346 21.6439C3.2489 22.1282 3.82521 22.3795 4.42103 22.1607L7.24096 21.0286C7.78046 21.4176 8.34703 21.7469 8.93315 22.0112L9.36215 25.0012C9.43253 25.571 9.92546 26 10.5105 26H15.4938C16.0777 26 16.5717 25.571 16.641 25.012L17.0711 22.0112C17.6388 21.7544 18.1956 21.4305 18.7611 21.0275L21.5886 22.1628C21.7251 22.2159 21.867 22.243 22.0133 22.243C22.4336 22.243 22.8203 22.0133 23.0153 21.6558L25.5113 17.3333C25.7887 16.8263 25.6673 16.2034 25.221 15.8492ZM13.001 17.3333C10.6112 17.3333 8.66771 15.3898 8.66771 13C8.66771 10.6102 10.6112 8.66668 13.001 8.66668C15.3908 8.66668 17.3343 10.6102 17.3343 13C17.3343 15.3898 15.3908 17.3333 13.001 17.3333Z" 
                      fill="#4E518F"
                    />
                  </svg>
                  <div class="desc">
                    <b>{{ $t('Settings') }}</b>
                  </div>
                </router-link>
                <router-link 
                  to="/profile/orders" 
                  class="item"
                >
                  <svg 
                    width="26" 
                    height="26" 
                    viewBox="0 0 26 26" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      d="M1.86816 3.45276H3.53125C4.02148 3.45276 4.45313 3.80042 4.56836 4.31897C8.02883 20.4318 6.83531 14.8744 7.09351 16.0767C7.23352 16.9467 7.98352 17.5867 8.87354 17.5867H21.1636C22.0135 17.5867 22.7535 16.9766 22.9136 16.1967L25.0935 7.72662C25.3228 6.62276 24.4825 5.55664 23.3235 5.55664H6.9624L6.51172 3.84924C6.20605 2.46057 4.95215 1.45276 3.53125 1.45276H1.86816C1.31543 1.45276 0.868164 1.90002 0.868164 2.45276C0.868164 3.00549 1.31543 3.45276 1.86816 3.45276ZM16.7636 10.0566C16.7636 9.50665 17.2136 9.05664 17.7635 9.05664C18.3135 9.05664 18.7635 9.50665 18.7635 10.0566V13.0867C18.7635 13.6367 18.3135 14.0867 17.7635 14.0867C17.2136 14.0867 16.7636 13.6367 16.7636 13.0867V10.0566ZM11.5635 10.0566C11.5635 9.50665 12.0135 9.05664 12.5635 9.05664C13.1135 9.05664 13.5635 9.50665 13.5635 10.0566V13.0867C13.5635 13.6367 13.1135 14.0867 12.5635 14.0867C12.0135 14.0867 11.5635 13.6367 11.5635 13.0867V10.0566Z" 
                      fill="#4E518F"
                    />
                    <path 
                      d="M11.4648 24.547C12.9836 24.547 14.2148 23.3158 14.2148 21.797C14.2148 20.2782 12.9836 19.047 11.4648 19.047C9.94606 19.047 8.71484 20.2782 8.71484 21.797C8.71484 23.3158 9.94606 24.547 11.4648 24.547Z" 
                      fill="#4E518F"
                    />
                    <path 
                      d="M19.0449 24.547C20.5637 24.547 21.7949 23.3158 21.7949 21.797C21.7949 20.2782 20.5637 19.047 19.0449 19.047C17.5261 19.047 16.2949 20.2782 16.2949 21.797C16.2949 23.3158 17.5261 24.547 19.0449 24.547Z" 
                      fill="#4E518F"
                    />
                  </svg>
                  <div class="desc">
                    <b>{{ $t('Orders') }}</b>
                  </div>
                </router-link>
                <router-link 
                  to="/profile/transactions" 
                  class="item"
                >
                  <svg 
                    width="26"
                    height="26" 
                    viewBox="0 0 26 26" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      d="M16.7146 10.8611C12.7886 10.8611 9.59556 14.0144 9.59556 17.8889C9.59556 21.7633 12.7886 24.9167 16.7146 24.9167C20.6406 24.9167 23.8337 21.7633 23.8337 17.8889C23.8337 14.0144 20.6406 10.8611 16.7146 10.8611ZM19.1351 18.8055H16.7146C16.202 18.8055 15.786 18.3949 15.786 17.8889V15.4017C15.786 14.8957 16.202 14.485 16.7146 14.485C17.2272 14.485 17.6432 14.8957 17.6432 15.4017V16.9722H19.1351C19.6477 16.9722 20.0637 17.3829 20.0637 17.8889C20.0637 18.3949 19.6477 18.8055 19.1351 18.8055ZM17.9527 1.08333H5.57175C3.68985 1.08333 2.16699 2.58666 2.16699 4.44444V20.3333C2.16699 22.1911 3.68985 23.6944 5.57175 23.6944H9.95461C8.56794 22.1422 7.73842 20.1133 7.73842 17.8889C7.73842 13 11.7622 9.02777 16.7146 9.02777C18.4232 9.02777 20.0079 9.49222 21.3575 10.3233V4.44444C21.3575 2.58666 19.8346 1.08333 17.9527 1.08333ZM8.04794 13.9167H6.80985C6.30223 13.9167 5.88128 13.5011 5.88128 13C5.88128 12.4989 6.30223 12.0833 6.80985 12.0833H8.04794C8.55556 12.0833 8.97652 12.4989 8.97652 13C8.97652 13.5011 8.55556 13.9167 8.04794 13.9167ZM11.7622 10.25H6.80985C6.30223 10.25 5.88128 9.83444 5.88128 9.33333C5.88128 8.83222 6.30223 8.41666 6.80985 8.41666H11.7622C12.2698 8.41666 12.6908 8.83222 12.6908 9.33333C12.6908 9.83444 12.2698 10.25 11.7622 10.25ZM16.7146 6.58333H6.80985C6.30223 6.58333 5.88128 6.16777 5.88128 5.66666C5.88128 5.16555 6.30223 4.74999 6.80985 4.74999H16.7146C17.2222 4.74999 17.6432 5.16555 17.6432 5.66666C17.6432 6.16777 17.2222 6.58333 16.7146 6.58333Z" 
                      fill="#4E518F"
                    />
                  </svg>

                  <div class="desc">
                    <b>{{ $t('Transactions') }}</b>
                  </div>
                </router-link>
                <div 
                  class="item" 
                  @click="$parent.logout"
                >
                  <svg 
                    width="26" 
                    height="26" 
                    viewBox="0 0 26 26" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_48_1166)">
                      <path 
                        fill-rule="evenodd" 
                        clip-rule="evenodd" 
                        d="M12.8138 21.859V23.1548C12.8138 24.1939 12.2959 25.0909 11.3961 25.6104C10.9631 25.8604 10.4799 25.9998 9.97885 26C9.47738 26.0003 8.9943 25.8606 8.56104 25.6104L2.7996 22.284C1.8996 21.7644 1.38184 20.8676 1.38184 19.8284V2.83552C1.38184 1.27192 2.6538 0 4.21736 0H16.9061C18.4698 0 19.7418 1.27182 19.7418 2.83552V6.41758C19.7418 6.93144 19.3246 7.34866 18.8108 7.34866C18.2968 7.34866 17.8799 6.93149 17.8799 6.41758V2.83552C17.8799 2.29861 17.443 1.86174 16.9061 1.86174H5.78746L11.3961 5.10042C12.2956 5.61991 12.8138 6.51671 12.8138 7.55554V19.9971H16.9061C17.443 19.9971 17.8799 19.5605 17.8799 19.0235V15.8834C17.8799 15.3692 18.2965 14.9523 18.8108 14.9523C19.3248 14.9523 19.7418 15.3693 19.7418 15.8834V19.0235C19.7418 20.5872 18.4698 21.859 16.9061 21.859H12.8138ZM21.4407 11.8604L20.4154 12.8857C20.0518 13.2492 20.0519 13.8386 20.4154 14.2022C20.5904 14.3773 20.8262 14.4746 21.0736 14.4746C21.3213 14.4746 21.5569 14.3775 21.7322 14.2022L24.3463 11.5876C24.7098 11.2241 24.7098 10.635 24.3463 10.2715L21.7322 7.6573C21.3686 7.29371 20.7792 7.29386 20.4154 7.65725C20.052 8.02044 20.0521 8.61006 20.4154 8.97335L21.4407 9.99842H14.572C14.0576 9.99842 13.6411 10.4152 13.6411 10.9295C13.6411 11.4439 14.0577 11.8604 14.572 11.8604H21.4407V11.8604Z" 
                        fill="#4E518F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_48_1166">
                        <rect 
                          width="26" 
                          height="26" 
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <div class="desc">
                    <b>{{ $t('Log out') }}</b>
                  </div>
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/settings'"
              class="profile-content profile-content-setings"
            >
              <div class="profile-content-left">
                <div class="info">
                  <div class="desc main-desc">
                    <b>{{ $t('General') }}</b>
                  </div>
                  <img 
                    src="./../img/profile/user.svg" 
                    class="img"
                  >
                  <div class="text">
                    <div class="desc">
                      <b>{{ name }} {{ surname }}</b>
                    </div>
                    <div 
                      class="desc" 
                      v-text="email"
                    />
                    <div class="balance">
                      <img 
                        src="./../img/wallet.svg" 
                        class="img"
                      >
                      <div class="desc big">
                        <b>
                          <span class="currency">{{ $parent.currency }}</span>
                          {{ balance }}
                        </b>
                      </div>
                      <button 
                        class="button blue" 
                        @click="$parent.openTopup"
                      >
                        {{ $t('Top up balance') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Change password') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Current password') }}
                    </div>
                    <input 
                      v-model="curPass"
                      type="text" 
                      :placeholder="$t('Current password')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('New password') }}
                    </div>
                    <input  
                      v-model="newPass"
                      type="text" 
                      :placeholder="$t('New password')"
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Confirm new password') }}
                    </div>
                    <input 
                      v-model="repeatPass"
                      type="text" 
                      :placeholder="$t('Confirm password')" 
                    >
                  </div>
                  <button 
                    class="button blue" 
                    @click="updatePassword"
                  >
                    <span>{{ $t('Save') }}</span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="passUpdateErrorMessaage"
                        class="desc red"
                      >
                        {{ $t(passUpdateErrorMessaage) }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="passUpdateSuccessMessaage"
                        class="desc green"
                      >
                        {{ $t(passUpdateSuccessMessaage) }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
              <div class="profile-content-right">
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Personal Information') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('First name') }}
                    </div>
                    <input 
                      v-model="name"
                      type="text" 
                      :placeholder="$t('First name')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Last name') }}
                    </div>
                    <input 
                      v-model="surname"
                      type="text" 
                      :placeholder="$t('Last name')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('E-mail') }}
                    </div>
                    <input
                      v-model="email"
                      type="email" 
                      :placeholder="$t('E-mail')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Phone') }}
                    </div>
                    <input 
                      v-model="phone"
                      type="tel" 
                      :placeholder="$t('Phone')" 
                    >
                  </div>
                </div>
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Billing address') }}</b>
                  </div>
                  <div 
                    v-if="countryOptions"
                    class="input-container" 
                  >
                    <div class="desc">
                      {{ $t('Country') }}
                    </div>
                    <select v-model="selectedCountry">
                      <option 
                        value="" 
                        disabled 
                        selected
                      >
                        {{ $t('Countries') }}  
                      </option>
                      <option 
                        v-for="item in countryOptions" 
                        :key="item.id"
                        :value="item"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('City') }}
                    </div>
                    <input 
                      v-model="city"
                      type="text" 
                      :placeholder="$t('City')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('ZIP Code') }}
                    </div>
                    <input 
                      v-model="zipCode"
                      type="text" 
                      :placeholder="$t('ZIP Code')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Address') }}
                    </div>
                    <input 
                      v-model="address"
                      type="text" 
                      :placeholder="$t('Address')" 
                    >
                  </div>
                  <button 
                    class="button blue" 
                    @click="updateProfileData"
                  >
                    <span>
                      {{ $t('Save') }}
                    </span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateErrorMessaage"
                        class="desc red" 
                      >
                        {{ $t(profileUpdateErrorMessaage) }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateSuccessMessaage"
                        class="desc green" 
                      >
                        {{ $t(profileUpdateSuccessMessaage) }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/transactions'"
              class="profile-content profile-content-transactions"
            >
              <div class="desc main-desc">
                <b>{{ $t('Payment hystory') }}</b>
              </div>
              <div 
                v-if="$parent.depositHistory"
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>{{ $t('ID') }}</td>
                          <td>{{ $t('Date') }}</td>
                          <td>{{ $t('Type') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.depositHistory" 
                          :key="i"
                        >
                          <td>{{ item.id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>{{ $t('Top up balance') }}</td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td v-else>
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination" 
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/orders'"
              class="profile-content profile-content-orders"
            >
              <div class="desc main-desc">
                <b>{{ $t('Order history') }}</b>
              </div>
              <div 
                v-if="$parent.orderHistory" 
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>{{ $t('ID') }}</td>
                          <td>{{ $t('Date') }}</td>
                          <td>{{ $t('Skin name') }}</td>
                          <td>{{ $t('Count') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.orderHistory" 
                          :key="i"
                        >
                          <td>{{ item.id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>
                            <img 
                              :src="$parent.imgDomain + item.img_url" 
                              class="img"
                            >
                            {{ item.title }}
                          </td>
                          <td class="desc">
                            {{ item.count }}
                          </td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td v-else>
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination"
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import Categories from '../components/Categories.vue';
export default {
  name: 'Profile',
  components: {
    Categories,
  },
  props: {
    isAuth: {
			type: Boolean,
			required: true
		},
    appBalance: {
			type: [Number, String],
			required: true
		}
  },
  data: function() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      country: '',
      selectedCountry: '',
      countryId: '',
      city: '',
      address: '',
      zipCode: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      balance: '0.00',
      countryOptions: []
    }
  },
  watch: {
    appBalance() {
      this.getProfile();
		},
  },
  mounted() {
    this.getCounties();
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.getProfile();
      })
      .catch((res) => {
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
        this.balance = res.data.balance;
        this.$emit('setAppBalance', res.data.balance);
        this.city = res.data.city;
        this.zipCode = res.data.zip;
        this.address = res.data.address;
        let countryId = this.countryOptions.find(obj => obj.id == res.data.country);
        this.selectedCountry = countryId;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "country": this.selectedCountry.id,
        "city": this.city,
        "postCode": this.zipCode,
        "address": this.address
      }
      
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = this.$t('Success');
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = this.$t('Success');
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
  }
}
</script>