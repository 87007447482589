<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <img 
            src="./../img/heroImg.png" 
            class="img"
          >
          <div class="title big">
            {{ $t('SKINS MARKET') }}
          </div>
          <div class="text">
            <div
              class="button blue"
              @click="setProductCategory('all')"
            >
              {{ $t('Shop now') }}
            </div>
            <div class="desc">
              {{ $t('CS2 Skin Market is your one-stop platform for all your skin trading needs.') }}
            </div>
          </div>
        </div>
      </div>
      <div class="section home-products-section">
        <div class="wrapper">
          <div class="desc">
            {{ $t('Discover Diverse Categories of CS2 Skins') }}
          </div>
          <div class="title">
            {{ $t('Elevate Your Style, Dominate the Game!') }}
          </div>
          <SlickItem 
            v-if="$parent.categoryOptions.length"
            :currency="currency"
            :is-auth="$parent.isAuth"
            :categories="$parent.categoryOptions"
            :list="$parent.categoryOptions" 
            @setProductCategory="setProductCategory"
          />
          <div
            class="button blue"
            @click="setProductCategory('all')"
          >
            {{ $t('Shop now') }}
          </div>
        </div>
      </div>
      <div class="section how-it-works-section">
        <div class="wrapper">
          <div class="title">
            {{ $t('How It Works') }}
          </div>
          <div class="list">
            <div class="item">
              <img 
                src="./../img/howItWorks/1.svg" 
                class="img"
              >
              <div class="title small">
                {{ $t('Browse:') }}
              </div>
              <div class="desc">
                {{ $t('Explore our diverse collection of CS2 skins and find the ones that speak to you.') }}
              </div>
            </div>
            <div class="item">
              <img 
                src="./../img/howItWorks/2.svg"  
                class="img"
              >
              <div class="title small">
                {{ $t('Buy:') }}
              </div>
              <div class="desc">
                {{ $t('Purchase your favorite skins directly from our platform using a variety of payment options.') }}
              </div>
            </div>
            <div class="item">
              <img 
                src="./../img/howItWorks/3_2.svg"  
                class="img"
              >
              <div class="title small">
                {{ $t('Sell:') }}
              </div>
              <div class="desc">
                {{ $t('Got some skins you no longer need? List them for sale on CS2 Skin Market and find interested buyers.') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section join-section">
        <div class="wrapper">
          <div class="title">
            {{ $t('Join Us Today!') }}
          </div>
          <div class="desc">
            {{ $t('Join Us Today Text') }}
          </div>
          <div 
            class="button" 
            @click="$parent.openSignIn"
          >
            <span>{{ $t('Sign In') }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import SlickItem from '../components/SlickItem.vue';

export default {
  name: 'Home',
  components: {
    SlickItem
  },
  props: {
    addToCartChosenItem: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      imgDomain: '',
      list: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>