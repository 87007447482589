<template>
  <div class="modal sign-modal">
    <div 
      class="overlay"
      @click="$emit('closeSignIn')"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              class="close" 
              src="./../img/close.svg"
              @click="$emit('closeSignIn')" 
            >
            <div 
              v-if="$parent.regIsAvailable"
              class="left" 
            >
              <div class="desc">
                <b>
                  {{ $t('Dont have an account?') }}
                </b>
              </div>
              <div class="title">
                {{ $t('Join Us Today!') }}
              </div>
              <button 
                class="button" 
                @click="$parent.openSignUp"
              >
                <span>
                  {{ $t('Sign Up') }}
                </span>
              </button>
            </div>
            <div class="right">
              <div :class="['form-fields login-content', {'hidden': !loginContentVisible}]">
                <div class="form-fields-wrapper">
                  <div class="title">
                    {{ $t('Sign In') }}
                  </div>
                  <label>
                    <div class="desc">
                      {{ $t('E-mail') }}
                    </div>
                    <input 
                      v-model="email"
                      type="email" 
                      :placeholder="$t('Email')"
                    >
                  </label>
                  <label>
                    <div class="desc">
                      {{ $t('Password') }}
                    </div>
                    <input 
                      v-model="pass"
                      type="password" 
                      :placeholder="$t('Password')"
                    >
                  </label>
                  <div class="cta-container">
                    <button 
                      class="button blue" 
                      @click="submitLogin"
                    >
                      <span>
                        {{ $t('Sign in with email') }}
                      </span>
                    </button>
                  </div>
                  <transition name="slide">
                    <div 
                      v-if="$parent.error" 
                      class="error-desc desc red"
                    >
                      {{ $t($parent.error) }}
                    </div>
                  </transition>
                  <div class="modal-bottom">
                    <div 
                      class="desc link" 
                      @click="switchToforgotPass"
                    >
                      <b>{{ $t('Forgot password?') }}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="['form-fields forgot-pass-content', {'hidden': !forgotPassContentVisible}]">
                <div class="form-fields-wrapper">
                  <div class="title small">
                    <b>{{ $t('Forgot password?') }}</b>
                  </div>
                  <label>
                    <div class="desc">{{ $t('E-mail:') }}</div>
                    <input 
                      v-model="email"
                      type="email" 
                      :placeholder="$t('Email')" 
                    >
                  </label>
                  <div class="cta-container">
                    <button 
                      class="button blue" 
                      @click="submitForgotPass"
                    >
                      <span>
                        {{ $t('Recover') }}
                      </span>
                    </button>
                  </div>
                  <transition name="fade">
                    <div 
                      v-if="$parent.successMessage" 
                      class="desc green"
                    >
                      {{ $t($parent.successMessage) }}
                    </div>
                  </transition>
                  <transition name="slide">
                    <div 
                      v-if="$parent.error" 
                      class="error-desc desc red"
                    >
                      {{ $t($parent.error) }}
                    </div>
                  </transition>
                  <div class="modal-bottom">
                    <div class="desc">
                      <b>{{ $t('Have account?') }}</b>
                    </div>
                    <div 
                      class="desc link switch-login" 
                      @click="switchToLogin()"
                    >
                      <b>{{ $t('Login') }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  props: [],
  data: function() {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
  }
}
</script>